import { createContext } from "react";
import MoonSvg from "./components/MoonSvg";
import SunSvg from "./components/SunSvg";

type ThemeKey = 'LIGHT' | 'DARK';

export interface ITheme {
  key: ThemeKey,
  rootClass: string,
  switcher: React.ReactNode,
  cell: {
    0: string,
    2: string,
    4: string,
    8: string,
    16: string,
    32: string,
    64: string,
    128: string,
    256: string,
    512: string,
    1024: string,
    2048: string
  },
  buttonClass: string
}

export const themes: {light: ITheme, dark: ITheme} = {
  light: {
    key: 'LIGHT',
    switcher: <MoonSvg />,
    rootClass: 'theme-light',
    cell: {
      0: "bg-gray-300",
      2: "bg-orange-300",
      4: "bg-orange-400",
      8: "bg-orange-600",
      16: "bg-orange-800",
      32: "bg-amber-400",
      64: "bg-amber-600",
      128: "bg-amber-800",
      256: "bg-yellow-400",
      512: "bg-yellow-600",
      1024: "bg-yellow-800",
      2048: "bg-red-400"
    },
    buttonClass: 'bg-amber-200 hover:bg-amber-300 text-black border-amber-600'
  },
  dark: {
    key: 'DARK',
    switcher: <SunSvg />,
    rootClass: 'theme-dark',
    cell: {
      0: "bg-slate-700/50",
      2: "bg-sky-900",
      4: "bg-blue-900",
      8: "bg-indigo-900",
      16: "bg-violet-900",
      32: "bg-purple-800",
      64: "bg-fuchsia-900",
      128: "bg-pink-900",
      256: "bg-rose-900",
      512: "bg-cyan-900",
      1024: "bg-pink-900",
      2048: "bg-rose-900"
    },
    buttonClass: 'bg-sky-500/50 hover:bg-sky-600/50 text-white border-sky-600'
  }
}

const ThemeContext = createContext<[ITheme, (theme: ITheme) => void]>([themes.light, () => {}]);

export default ThemeContext;