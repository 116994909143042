type MoveResult = {
  isGameOver: boolean
};

interface IEquatable<T> {
  equals(other: IEquatable<T>): boolean
}

export interface Wrapped<T> {
  position: [row: number, col: number],
  element: T,
  isChild: boolean,
  isParent: boolean,
  isNew: boolean
}

export class Element implements IEquatable<Element> {
  private static _counter: number = 0;
  private readonly _id: string;
  private _value: number = 2;
  private _child?: Element;

  private constructor(id: string, value: number, child?: Element) {
    this._id = id;
    this._value = value;
    this._child = child;
  }

  private static generateId(): string {
    return `element-${Element._counter++}`
  }

  get value(): number {
    return this._value;
  }

  get id(): string {
    return this._id;
  }

  get isEmpty(): boolean {
    return this._value == 0;
  }

  get isNotEmpty(): boolean {
    return this._value != 0;
  }

  get hasChild(): boolean {
    return this._child !== undefined;
  }

  get child(): Element | undefined {
    return this._child;
  }

  removeChild(): void {
    this._child = undefined;
  }

  equals(other: Element): boolean {
    return this._value == other.value;
  }

  merge(other: Element): Element {
    return new Element(this.id, this._value + other.value, other);
  }

  static get WithoutValue(): Element {
    return new Element(Element.generateId(), 0);
  }

  static get WithValue(): Element {
    return new Element(Element.generateId(), 2);
  }
}

/** A function that is used to collapse an array to the LEFT. Example:
 * input: [null, 2, null, 2, 4]
 * output: [4, 4]
 */
function arraysAreSame<T>(arr1: IEquatable<T>[], arr2: IEquatable<T>[]): boolean {
  if (arr1.length != arr2.length) {
    return false;
  }

  for(let i = 0; i < arr1.length; i++) {
    if (!arr1[i].equals(arr2[i])) {
      return false;
    }
  }

  return true;
}

export function collapseArrayLeft(arr: Element[]): {somethingChanged: boolean, arr: Element[], points: number} {
  var skipNext = false;
  let points = 0;
  let result: Element[] = [];
  const temp: Element[] = arr.filter((element) => element.isNotEmpty);
  for (let i = 0; i < temp.length; i++) {
    const element = temp[i];
    element.removeChild();

    if (skipNext) {
      skipNext = false;
      continue;
    }
    
    const isLastElement = i == temp.length - 1;
    if(isLastElement) {
      result.push(element);
      break;
    }

    const nextElement = temp[i + 1];
    nextElement.removeChild();

    if (element.equals(nextElement)) {
      result.push(element.merge(nextElement));
      points += element.value;
      skipNext = true;
    } else {
      result.push(element);
    }
  }

  result = fillRemainingSpace(result, Element.WithoutValue, arr.length, "left");
  const somethingChanged = arraysAreSame(result, arr) == false;

  return { somethingChanged, arr: result, points };
}

/** A function that is used to collapse an array to the RIGHT. Example:
 * input: [null, 2, null, 2, 4]
 * output: [4, 4]
 */
export function collapseArrayRight(arr: Element[]): {somethingChanged: boolean, arr: Element[], points: number } {
  var skipNext;
  let points = 0;
  let result: Element[] = [];
  const temp: Element[] = arr.filter((element) => element.isNotEmpty);

  for (let i = temp.length - 1; i >= 0; i--) {
    const element = temp[i];
    element.removeChild();

    if (skipNext) {
      skipNext = false;
      continue;
    }
    
    const isLastElement = i == 0;
    if(isLastElement) {
      result.unshift(element);
      break;
    }

    const previousElement = temp[i - 1];
    previousElement.removeChild();
    
    if (element.equals(previousElement)) {
      result.unshift(element.merge(previousElement));
      points += element.value;
      skipNext = true;
    } else {
      result.unshift(element);
    }
  }

  result = fillRemainingSpace(result, Element.WithoutValue, arr.length, "right");
  const somethingChanged = arraysAreSame(result, arr) == false;

  return { somethingChanged, arr: result, points };
}


export function collapseGridDown(grid: Element[][], rowCount: number, colCount: number): { somethingChanged: boolean, grid: Element[][], points: number } {
  const result: Element[][] = [];
  let changed: boolean = false;
  let totalPoints: number = 0;

  for(let i = 0; i < rowCount; i++) {
    result.push([]);
  }

  for(let col = 0; col < colCount; col++) {
    let column: Element[] = [];
    for(let row = 0; row < rowCount; row++) {
      const element = grid[row][col];
      column.push(element);
    }

    const { somethingChanged, arr, points } = collapseArrayRight(column);
    const padded = fillRemainingSpace(arr, Element.WithoutValue, 4, "right");

    for(let row = 0; row < rowCount; row++) {
      result[row].push(padded[row]);
    }

    totalPoints += points;
    if (somethingChanged) {
      changed = true;
    }
  }

  return { somethingChanged: changed, grid: result, points: totalPoints };
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

/** A function used to pad an array with values until it reaches a certain length. Example:
 * input: [1, 2, 3]
 * args: value: null, targetLength: 6, direction: "left",
 * output: [1, 2, 3, null, null, null]
 */
export function fillRemainingSpace(arr: any[], value: any, targetLength: number, direction: "right" | "left"): any[] {
  const temp = [...arr];
  while(temp.length < targetLength) {
    if (direction == "left") {
      temp.push(value);
    }
    if (direction == "right") {
      temp.unshift(value);
    }
  }
  return temp;
}

export function rotateLeft(arr: any[][], length: number): any[][] {
  const result: any[][] = [...arr.map((inner) => [...inner])];
  for (let x = 0; x < length / 2; x++)
  {
    for (let y = x; y < length - x - 1; y++)
    {
      let temp = result[x][y];
      result[x][y] = result[y][length - 1 - x];
      result[y][length - 1 - x]
          = result[length - 1 - x][length - 1 - y];
      result[length - 1 - x][length - 1 - y] = result[length - 1 - y][x];
      result[length - 1 - y][x] = temp;
    }
  }

  return result;
}

export function rotateRight(arr: any[][], length: number): any[][] {
  const N = length;
  const result: any[][] = [...arr.map((inner) => [...inner])];

  function swap(a: any, b: any) {
    const temp = a;
    a = b;
    b = temp;
  }

  // Transpose the matrix
  for (let i = 0; i < N; i++)
  {
    for (let j = 0; j < i; j++) {
      var temp = result[i][j];
      result[i][j] = result[j][i];
      result[j][i] = temp;
    }
  }

  // swap columns
  for (let i = 0; i < N; i++)
  {
    for (let j = 0; j < N/2; j++) {
      var temp = result[i][j];
      result[i][j] = result[i][N - j - 1];
      result[i][N - j - 1] = temp;
      swap(result[i][j], result[i][N - j - 1]);
    }
  }

  return result;
}

export class GameEngine {
  private _points: number = 0;
  private _moveCount: number = 0;
  private _rows: Element[][] = [];
  private _newElementId?: string;

  constructor() {
    this.reset();
  }

  get rows() {
    return this._rows;
  }

  get elements(): Wrapped<Element>[] {
    const mapElement = (element: Element, rowIndex: number, colIndex: number): Wrapped<Element>[] => {
      const position: [row: number, col: number] = [rowIndex, colIndex];
      if (element.isEmpty) {
        return [
          { element, position, isParent: false, isChild: false, isNew: false }
        ];
      }
      
      let isNew = false;
      if (this._newElementId) {
        isNew = element.id === this._newElementId;
      }

      if (element.hasChild && element.child != undefined) {
        return [
          { element, position, isParent: true, isChild: false, isNew },
          { element: element.child, position, isParent: false, isChild: true, isNew }
        ];
      }
      
      return [
        { element, position, isParent: false, isChild: false, isNew }
      ];
    }

    const elements = this._rows
      .map((row, rowIndex) => row
        .map((el: Element, colIndex) => {
            return mapElement(el, rowIndex, colIndex);
          }))
          .flat(3);

    return elements;
  }

  get score() {
    return this._points;
    //return Math.round(this._points/this._moveCount) || 0;
  }

  public collapseLeft(): MoveResult {
    let shouldGenerateNewElement = false;
    this._rows = this._rows.map((arr) => {
      const collisionResult = collapseArrayLeft(arr);
      this._points += collisionResult.points;
      if (collisionResult.somethingChanged) {
        shouldGenerateNewElement = true;
      }

      return collisionResult.arr;
    });

    if (shouldGenerateNewElement) {
      this._moveCount++;
      this.generateElementAtRandomPosition();
    }

    return { isGameOver: this.isGameOver() };
  }

  public collapseRight(): MoveResult {
    let shouldGenerateNewElement = false;
    this._rows = this._rows.map((arr) => {
      const collisionResult = collapseArrayRight(arr);
      this._points += collisionResult.points;
      if (collisionResult.somethingChanged) {
        shouldGenerateNewElement = true;
      }

      return collisionResult.arr;
    });

    if (shouldGenerateNewElement) {
      this._moveCount++;
      this.generateElementAtRandomPosition();
    }

    return { isGameOver: this.isGameOver() };
  }

  public collapseUp(): MoveResult {
    let shouldGenerateNewElement = false;
    const rotated = rotateLeft(this._rows, 4);
    const transformed = rotated.map((arr) => {
      const collisionResult = collapseArrayLeft(arr);
      this._points += collisionResult.points;
      if (collisionResult.somethingChanged) {
        shouldGenerateNewElement = true;
      }

      return collisionResult.arr;
    });
    const rotatedBack = rotateRight(transformed, 4);
    this._rows = rotatedBack;

    if (shouldGenerateNewElement) {
      this._moveCount++;
      this.generateElementAtRandomPosition();
    }

    return { isGameOver: this.isGameOver() };
  }

  public collapseDown(): MoveResult {
    let shouldGenerateNewElement = false;
    const { somethingChanged, grid, points } = collapseGridDown(this._rows, 4, 4);
    this._rows = grid;
    this._points += points;
    if (somethingChanged) {
      shouldGenerateNewElement = true;
    }
    /* const rotated = rotateLeft(this._rows, 4);
    const transformed = rotated.map((arr) => {
      const collisionResult = collapseArrayRight(arr);
      this._points += collisionResult.points;
      if (collisionResult.somethingChanged) {
        shouldGenerateNewElement = true;
      }

      return collisionResult.arr;
    });
    const rotatedBack = rotateRight(transformed, 4);
    this._rows = rotatedBack; */
    

    if (shouldGenerateNewElement) {
      this._moveCount++;
      this.generateElementAtRandomPosition()
    }

    return { isGameOver: this.isGameOver() };
  }

  public reset(): void {
    this._rows = [
      //[Element.WithValue, Element.WithValue, Element.WithValue, Element.WithValue],
      [Element.WithoutValue, Element.WithoutValue, Element.WithoutValue, Element.WithoutValue],
      [Element.WithoutValue, Element.WithoutValue, Element.WithoutValue, Element.WithoutValue],
      [Element.WithoutValue, Element.WithoutValue, Element.WithoutValue, Element.WithoutValue],
      [Element.WithoutValue, Element.WithoutValue, Element.WithoutValue, Element.WithoutValue],
      // [Element.WithoutValue, Element.WithoutValue, Element.WithoutValue, Element.WithoutValue],
    ]
    this.generateElementAtRandomPosition();
    this.generateElementAtRandomPosition();
    this._moveCount = 0;
    this._points = 0;
  }

  private generateElementAtRandomPosition(): void {
    const coordinates: number[][] = [];
    for(let i = 0; i < this._rows.length; i++) {
        for(let j = 0; j < this._rows[i].length; j++) {
            if(this._rows[i][j].isEmpty) {
                coordinates.push([i, j]);
            }
        }
    }

    if(coordinates.length == 0) {
        return;
    }

    const rand = getRandomInt(new Date().getMilliseconds() % coordinates.length);
    const [row, col] = coordinates[rand];

    const newElement = Element.WithValue
    this._rows[row][col] = newElement;
    this._newElementId = newElement.id;
  }

  private isEmpty(row: number, col: number) {
    return this._rows[row][col].isEmpty;
  }

  private isGameOver(): boolean {
    const flow = [
      { target: [0, 0], adjacent: [ [1, 0] ] },
      { target: [1, 0], adjacent: [ [2, 0] ] },
      { target: [2, 0], adjacent: [ [3, 0] ] },


      { target: [0, 1], adjacent: [ [0, 0], [1, 1], [0, 2] ] },
      { target: [1, 1], adjacent: [ [1, 0], [2, 1], [1, 2] ] },
      { target: [2, 1], adjacent: [ [2, 0], [3, 1], [2, 2] ] },
      { target: [3, 1], adjacent: [ [3, 0], [3, 2] ] },

      { target: [0, 2], adjacent: [ [0, 3], [1, 2] ] },
      { target: [1, 2], adjacent: [ [1, 3], [2, 2] ] },
      { target: [2, 2], adjacent: [ [2, 3], [3, 2] ] },
      { target: [3, 2], adjacent: [ [3, 3] ] },

      { target: [0, 3], adjacent: [ [1, 3] ] },
      { target: [1, 3], adjacent: [ [2, 3] ] },
      { target: [2, 3], adjacent: [ [3, 3] ] },
    ]
    
    for(const entry of flow) {
      const [row, col] = entry.target;
      if (this.isEmpty(row, col)) {
        return false;
      }
      const target = this._rows[row][col];

      for(const xy of entry.adjacent) {
        const [row1, col1] = xy;
        if (this.isEmpty(row1, col1)) {
          return false;
        }

        if (target.equals(this._rows[row1][col1])) {
          return false;
        }
      }
    }

    return true;
  }
}
