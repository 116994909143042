import React, { FunctionComponent } from "react";
import { useSwipeable } from "react-swipeable";

interface ISwipeWrapperProps { 
  onSwipeLeft?(),
  onSwipeRight?(),
  onSwipeUp?(),
  onSwipeDown?(),
  children: React.ReactNode
}

const Section: FunctionComponent<ISwipeWrapperProps> = ({ onSwipeDown, onSwipeLeft, onSwipeRight, onSwipeUp, children }) => {
  const handlers = useSwipeable({
    onSwipedLeft: onSwipeLeft && (() => onSwipeLeft()),
    onSwipedRight: onSwipeRight && (() => onSwipeRight()),
    onSwipedUp: onSwipeUp && (() => onSwipeUp()),
    onSwipedDown: onSwipeDown && (() => onSwipeDown()),
  });

  return (
    <div {...handlers}>
      {children}
    </div>
  )
}

export default Section;