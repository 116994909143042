import React, { StrictMode, useEffect, useState } from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PageHome from './pages/Home';
import ThemeContext, { themes } from "./ThemeContext";

const App = () => {
  const currentTheme = useState(themes.dark);
  const [theme, setTheme] = currentTheme;

  useEffect(() => {
    document.body.className = theme.rootClass;
  }, [theme]);

  const handleThemeSwitch = () => {
    const map = {
      'LIGHT': themes.dark,
      'DARK': themes.light
    };

    setTheme(map[theme.key]);
  };

  return (
    <StrictMode>
        <ThemeContext.Provider value={currentTheme}>
          <button onClick={handleThemeSwitch} style={{width: 30, position: 'absolute', top: 20, right: 20}}>
            {theme.switcher}
          </button>
          <BrowserRouter>
          <Routes>
                <Route path="/" element={<PageHome />} />
                <Route path="*" element={<Navigate to="/" replace />}/>
              </Routes>
          </BrowserRouter>
        </ThemeContext.Provider>
    </StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);