import { animated, useSpring } from "@react-spring/web";
import React, { CSSProperties, FunctionComponent, useContext, useState } from "react";
import { Element, Wrapped } from "../core";
import ThemeContext from "../ThemeContext";

const fontSize: {[key: number]: string} = {
  0: "sm:text-6xl text-5xl",
  2: "sm:text-6xl text-5xl",
  4: "sm:text-6xl text-5xl",
  8: "sm:text-6xl text-5xl",
  16: "sm:text-6xl text-5xl",
  32: "sm:text-6xl text-5xl",
  64: "sm:text-6xl text-5xl",
  128: "sm:text-5xl text-4xl",
  256: "sm:text-5xl text-4xl",
  512: "sm:text-5xl text-4xl",
  1024: "sm:text-4xl text-3xl",
  2048: "sm:text-4xl text-3xl"
}

const EmptyCell: FunctionComponent<{ refCallback?(ref: HTMLDivElement | null) }> = ({ refCallback }) => {
  const [theme] = useContext(ThemeContext);
  
  const color = theme.cell['0'];
  const size = fontSize['0'];

  return (
    <div ref={refCallback} style={{ position: 'relative', transition: 'background-color .2s' }}
        className={`aspect-square flex justify-center items-center rounded ${color} ${size}`}>
        <span className="font-bold text-white drop-shadow-md"></span>
    </div>
  );
}

interface ICellProps {
  value: number, 
  style: CSSProperties, 
  isParent: boolean, 
  isNew: boolean,
  transformRule: string
};

const Cell: FunctionComponent<ICellProps> = ({ value, style, isParent, isNew, transformRule }) => {
  const [theme] = useContext(ThemeContext);

  const wrapperStyle = useSpring({
    from: { transform: `scale(-1) ${transformRule}`, filter: 'opacity(0%)' },
    to: { transform: `scale(1) ${transformRule}`, filter: 'opacity(100%)' },
    config: { duration: 200 },
  });

  const parentStyles = useSpring({
    to: [
      { transform: `scale(1.2) ${transformRule}` },
      { transform: `scale(1) ${transformRule}` },
    ],
    from: { transform: `scale(1) ${transformRule}` },
    config: { duration: 100 },
  })

  const color = theme.cell[value];
  const size = fontSize[value];

  return (
    <animated.div style={{ position: 'absolute', transition: 'transform .25s, filter 1s, background-color .2s', ...style, ...(isParent ? parentStyles : wrapperStyle )}}
      className={`flex justify-center items-center rounded ${color} ${size}`}>
      <span className="font-bold text-white drop-shadow-md">{value}</span>
    </animated.div>
  );
}

interface IGridProps { 
  elements: Wrapped<Element>[]
}

const Grid: FunctionComponent<IGridProps> = ({ elements }) => {
  const [staticCellRefs, setStaticCellRefs] = useState<(HTMLDivElement | null)[][]>([[], [], [], []]);

  const gridReplica = [
    [{}, {}, {}, {}],
    [{}, {}, {}, {}],
    [{}, {}, {}, {}],
    [{}, {}, {}, {}],
  ]

  const addStaticCellRef = (r, rowIndex, colIndex) => {
    // this condition is really important so that
    // we don't end up in an endless setstate -> render loop
    if (staticCellRefs[rowIndex][colIndex] != null) {
      return;
    }

    staticCellRefs[rowIndex][colIndex] = r;
    setStaticCellRefs(staticCellRefs.slice());
  }

  const createComponent = (wrapped: Wrapped<Element>): React.ReactNode => {
    if (wrapped.element.isEmpty) {
      return null;
    }

    const [ rowIndex, colIndex ] = wrapped.position;
    const { isParent, isChild, isNew } = wrapped;

    const cellRef = staticCellRefs[rowIndex][colIndex];

    if (cellRef == null) {
      return null;
    }

    const { width, height } = cellRef.getBoundingClientRect();
    const { offsetTop, offsetLeft } = cellRef;
    
    const zIndexMap = {
      "default": 1,
      "parent": 2,
      "new": 0
    };

    const zIndex = zIndexMap[isParent ? "parent" : isNew ? "new" : "default"];

    /* const transformRule = isNew
    ? `translate(${0}px, ${0}px)`  
    : `translate(${offsetLeft}px, ${offsetTop}px)`; */
    
    const transformRule = `translate(${offsetLeft}px, ${offsetTop}px)`;

    const style = isNew
      ? { /* top: `${offsetTop}px`, left: `${offsetLeft}px` */ }
      : { };

    return (
      <Cell
        value={wrapped.element.value}
        key={wrapped.element.id}
        isParent={isParent}
        isNew={isNew}
        transformRule={transformRule}
        style={{ ...style, width, height, zIndex }} 
      />
    );
  }

  return (
    <div className="w-full max-w-[500px] grid grid-cols-4 grid-rows-4 gap-3 p-2" style={{ position: 'relative' }}>
        {gridReplica.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
              {row.map((el, colIndex) => (
                <EmptyCell refCallback={(r) => addStaticCellRef(r, rowIndex, colIndex)} key={`${rowIndex}-${colIndex}`}/>
              ))}
          </React.Fragment>
        ))}

        {elements.map((el) => createComponent(el))}
    </div>
  )
}

export default Grid;