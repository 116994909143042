import { Component, useContext } from "react";
import { GameEngine, Element, Wrapped } from "../core";
import ThemeContext, { ITheme } from "../ThemeContext";
import Grid from "./Grid";
import SwipeWrapper from "./SwipeWrapper";

interface IGameProps {
  theme: ITheme;
}

interface IGameState {
    // grid: Element[][],
    elements: Wrapped<Element>[],
    score: number,
    isGameOver: boolean
}

class GameComponent extends Component<IGameProps, IGameState> {
  private readonly engine: GameEngine;

  constructor(props) {
    super(props);
    this.engine = new GameEngine();

    this.state = {
        // grid: this.engine.rows,
        elements: this.engine.elements,
        score: this.engine.score,
        isGameOver: false
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onArrowPress);
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onArrowPress);
    window.removeEventListener('resize', this.onResize);
  }

  private readonly onResize = () => {
    this.forceUpdate();
  }

  private readonly onArrowPress = (event: KeyboardEvent) => {
    switch(event.key) {
        case "ArrowRight":
            return this.move("right");
        case "ArrowLeft":
            return this.move("left");
        case "ArrowUp":
            return this.move("up");
        case "ArrowDown":
            return this.move("down");
        default:
            break;
    }
  }

  private readonly move = (direction: "up" | "right" | "down" | "left") => {
    const map = {
        "up": () => this.engine.collapseUp(),
        "right": () => this.engine.collapseRight(),
        "down": () => this.engine.collapseDown(),
        "left": () => this.engine.collapseLeft(),
    };

    const { isGameOver } = map[direction]();
    this.setState({
        // grid: this.engine.rows,
        elements: this.engine.elements,
        score: this.engine.score,
        isGameOver
    });
  }

  private readonly handleNewGameClick = () => {
    this.engine.reset();
    this.setState({
      //grid: this.engine.rows,
      elements: this.engine.elements,
      score: this.engine.score,
      isGameOver: false
    })
  }

  render() {
    return (
      <SwipeWrapper 
        onSwipeDown={() => this.move('down')}
        onSwipeUp={() => this.move('up')}
        onSwipeRight={() => this.move('right')}
        onSwipeLeft={() => this.move('left')}
      >
        <div className="w-full flex flex-col items-center justify-center py-2">
          <p className="text-2xl sm:text-4xl">
            <span className="font-bold">Score</span>: {this.state.score}
          </p>
          <p className="text-sm secondary-text">(points/moves)</p>  
        </div>
        
        <div className="w-full flex align-center items-center justify-center">
          <Grid elements={this.state.elements} />
        </div>

        {this.state.isGameOver && (
          <div className="w-full flex flex-col align-center items-center justify-center">
            <p>Oops! You're out of moves!</p>
            <button className={`font-semibold py-2 px-4 my-2 border rounded shadow ${this.props.theme.buttonClass}`} onClick={this.handleNewGameClick}>
              New game
            </button>
          </div>
        )}
      </SwipeWrapper>
    )
  }
}

const GameComponentWithTheme = () => {
  const [theme] = useContext(ThemeContext);

  return <GameComponent theme={theme} />
}

export default GameComponentWithTheme;